<!--
 * @Author: filename
 * @Description: 家庭成员-子女信息
-->
<template>
    <div class="mt-20">
        <Container title="子女信息" padding="0">
            <div v-loading="isLoading" v-if="data.child.length > 0">
                <div v-for="(item, i) in data.child" :key="i">
                    <div :class="['title-box', i > 0 ? 'mt-20' : '']" :id="'children'+i">
                        <span>
                            子女{{ i+1 }}/{{data.child.length}}
                            &nbsp;&nbsp;
                        </span>
                    </div>
                    <!-- 显示 -->
                    <ChildrenDetail v-if="!item.isEdit" :data="item" />
                </div>
            </div>
            <div v-else class="no-data">暂无数据</div>
        </Container>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import ChildrenDetail from './C/ChildrenDetail.vue'
export default {
    props: {
        data: {
            type: Object,
            default:() => {}
        },
    },
    components: {
        ChildrenDetail,
    },
    data() {
        return {
            isLoading: false,
        }
    },
    computed: {
        ...mapState('gifted', ['dataList'])
    },
    created() {
        this.setIsEdit()
    },
    methods: {
        // set isEdit
        setIsEdit() {
            this.data.child.forEach((item, i) => {
                this.$set(this.data.child[i], 'isEdit', false)
                this.$set(this.data.child[i], 'isLoading', false)
            })
        },
    }
}
</script>

<style lang="less" scoped>
.tooltip{
    display: inline-block;
    border-radius: 100%;
    background: #1296db;
    color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border: 1px #377395 solid;
    // height: 100%;
}
.content{
    padding: 3px;
    div{
        margin: 10px 0;
    }
}
</style>
