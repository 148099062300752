<!--
 * @Author: filename
 * @Description: 家庭成员-配偶信息
-->

<template>
    <div class="details-module common-part">
        <el-row>
            <el-col :span="12">
                <span>姓名：</span>
                <span>{{ data.subset_name }}</span>
            </el-col>
            <el-col :span="12">
                <span>曾用名：</span>
                <span>{{ data.used_name }}</span>
            </el-col>
            <el-col :span="12">
                <span>姓名拼音：</span>
                <span>{{ data.subset_name_pinyin.family_name + ' ' + data.subset_name_pinyin.given_name }}</span>
            </el-col>
            <el-col :span="12">
                <span>性别：</span>
                <span>{{ data.sex | _sex }}</span>
            </el-col>
            <el-col :span="12">
                <span>出生日期：</span>
                <span>{{ data.birthday }}</span>
            </el-col>
            <el-col :span="12">
                <span>出生城市：</span>
                <span>{{ data.birth_place_aboard === 0 ? data.birth_place.area.join('') : data.birth_place.foreign }}</span>
            </el-col>
            <el-col :span="12">
                <span>是否在职：</span>
                <span>{{ data.occupation }}</span>
            </el-col>
            <el-col :span="12">
                <span>职位名称：</span>
                <span>{{ data.occupation_name }}</span>
            </el-col>
            <template v-if="data.follow == 1">
                <el-col :span="12">
                    <span>国籍/地区：</span>
                    <span>{{ data.nationality }}</span>
                </el-col>
                <el-col :span="24">
                    <el-divider />
                </el-col>
                <el-col :span="12">
                    <span>是否有学位：</span>
                    <span class="red">{{ data.has_degree == 1 ? '有学位' : data.has_degree == 0 ? '无学位' : '' }}</span>
                </el-col>
                <el-col :span="12" style="align-items: normal;" v-if="data.has_degree == 1">
                    <span>学位证：</span>
                    <span>
                        <span style="display: block;" v-for="(item, i) in data.has_degree_json" :key="i">
                            {{ item.degree == 1 ? '学士学位' : item.degree == 2 ? '硕士学位' : item.degree == 3 ? '博士学位' : '' }}
                            -
                            {{ item.address == 1 ? '国内（颁发）' : item.address == 2 ? '国外（颁发）' : '' }}
                        </span>
                    </span>
                </el-col>
                <el-col :span="24">
                    <el-divider />
                </el-col>
                <el-col :span="12">
                    <span>是否与主申请人同住：</span>
                    <span class="red">{{ data.nows == 1 ? '是' : data.nows == 0 ? '否' : '' }}</span>
                </el-col>
                <el-col :span="12">
                    <span>是否永久居留身份：</span>
                    <span>{{ data.permanent_residence == 1 ? '是' : data.permanent_residence == 0 ? '否' : '' }}</span>
                </el-col>
                <el-col :span="24" style="align-items: normal;" v-if="data.nows == 0">
                    <span>现居住地址：</span>
                    <span v-if="data.address.country && data.address.country.includes('中国')">{{ data.address.country + ' - ' + data.address.area[0] + data.address.area[1] + ' - ' + data.address.details }}</span>
                    <span v-else>{{ data.address.country + ' - ' + data.address.foreign }}</span>
                </el-col>
                <el-col :span="12">
                    <span>总居留时间：</span>
                    <span>{{ data.permanent_reside_year }}年{{ data.permanent_reside_month }}月</span>
                </el-col>
                <el-col :span="24">
                    <el-divider />
                </el-col>
                <el-col :span="24">
                    <span>是否在港：</span>
                    <span class="red" v-if="data.child_in_hk.has">{{ data.child_in_hk.has == 'Y' ? '是' : '否' }}</span>
                </el-col>
                <template v-if="data.child_in_hk.has == 'Y'">
                    <el-col :span="12">
                        <span>获准逗留至：</span>
                        <span>{{ data.child_in_hk.info }}</span>
                    </el-col>
                    <el-col :span="12">
                        <span>在港身份：</span>
                        <span>{{ data.hk_identity == '其他' ? data.hk_identity_other : data.hk_identity }}</span>
                    </el-col>
                </template>
            </template>
            <el-col :span="24">
                <el-divider />
            </el-col>
            <el-col :span="12">
                <span>是否办理过香港身份：</span>
                <span class="red" v-if="data.child_has_hk_id.has">{{ data.child_has_hk_id.has == 'Y' ? '是' : '否' }}</span>
            </el-col>
            <el-col :span="12" v-if="data.child_has_hk_id.has == 'Y'">
                <span>香港身份证：</span>
                <span>{{ data.hk_identity_card }}</span>
            </el-col>
            <template v-if="data.follow == 1">
                <el-col :span="24">
                    <el-divider />
                </el-col>
                <el-col :span="24">
                    <span>证件类别：</span>
                    <span>中国身份证</span>
                </el-col>
                <el-col :span="12">
                    <span>证件号码：</span>
                    <span>{{ data.certificates.cn_identity_card.number }}</span>
                </el-col>
                <el-col :span="12">
                    <span>签发地：</span>
                    <span>{{ data.certificates.cn_identity_card.issue_at }}</span>
                </el-col>
                <el-col :span="12">
                    <span>签发日期：</span>
                    <span>{{ data.certificates.cn_identity_card.issue_date_start_at }}</span>
                </el-col>
                <el-col :span="12">
                    <span>届满日期：</span>
                    <span>{{ data.certificates.cn_identity_card.issue_date_end_at }}</span>
                </el-col>
                <el-col :span="24">
                    <el-divider />
                </el-col>
                <el-col :span="24">
                    <span>证件类别：</span>
                    <span>港澳通行证</span>
                </el-col>
                <el-col :span="12">
                    <span>证件号码：</span>
                    <span>{{ data.certificates.hk_macao_pass.number }}</span>
                </el-col>
                <el-col :span="12">
                    <span>签发地：</span>
                    <span>{{ data.certificates.hk_macao_pass.issue_at }}</span>
                </el-col>
                <el-col :span="12">
                    <span>签发日期：</span>
                    <span>{{ data.certificates.hk_macao_pass.issue_date_start_at }}</span>
                </el-col>
                <el-col :span="12">
                    <span>届满日期：</span>
                    <span>{{ data.certificates.hk_macao_pass.issue_date_end_at }}</span>
                </el-col>
                <el-col :span="24">
                    <el-divider />
                </el-col>
                <el-col :span="24">
                    <span>证件类别：</span>
                    <span>护照</span>
                </el-col>
                <el-col :span="12">
                    <span>证件号码：</span>
                    <span>{{ data.certificates.passport.number }}</span>
                </el-col>
                <el-col :span="12">
                    <span>签发地：</span>
                    <span>{{ data.certificates.passport.issue_at }}</span>
                </el-col>
                <el-col :span="12">
                    <span>签发日期：</span>
                    <span>{{ data.certificates.passport.issue_date_start_at }}</span>
                </el-col>
                <el-col :span="12">
                    <span>届满日期：</span>
                    <span>{{ data.certificates.passport.issue_date_end_at }}</span>
                </el-col>
            </template>
        </el-row>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
            
        }
    }
}
</script>

<style lang="less" scoped>
.red {
    color: red;
}
</style>
