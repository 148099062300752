<!--
 * @Author: filename
 * @Description: 家庭成员-配偶信息
-->
<template>
    <Container title="配偶" padding="0">
        <template v-if="data.spouse">
            <div class="title-box">
                <span>
                    配偶信息&nbsp;&nbsp;
                </span>
            </div>
            <!-- 回显信息 -->
            <SpouseDetail v-if="!data.spouse.isEdit" :data="data.spouse" />
        </template>
        <div v-else class="no-data">暂无数据</div>
    </Container>
</template>

<script>
import SpouseDetail from './C/SpouseDetail.vue'
import { postClientInfo } from '../../../../../../api/gifted'
export default {
    props: {
        data: {
            type: Object,
            default:() => {}
        },
    },
    components: {
        SpouseDetail,
    },
    data() {
        return {
        }
    },
    created() {
        this.$set(this.data.spouse, 'isEdit', false)
    },
    methods: {
        // 配偶 type 1 随行 2不随行
        addSpouse(type) {
            this.data.spouse =  {
                isEdit: true,
                subset_name_pinyin: {
                    family_name: '',
                    given_name: ''
                },
                relation: 3,
                nationality: '',
                follow: 1,
                subset_name: '',
                used_name: '',
                birthday: '',
                birth_place_aboard: 0,
                birth_place: {
                    area: [],
                    country: '',
                    details: '',
                    foreign: ''
                },
                occupation: '',
                occupation_name: '',
                child_has_hk_id: {
                    has: ''
                },
                hk_identity_card: '',
                // 随行
                has_degree: '',
                has_degree_json: [
                    {
                        degree: '',
                        address: ''
                    }
                ],
                certificates: {
                    cn_identity_card: {
                        img_back: '',
                        img_front: '',
                        issue_at: '',
                        issue_date_end_at: '',
                        issue_date_start_at: '',
                        number: ''
                    },
                    hk_macao_pass: {
                        img_back: '',
                        img_front: '',
                        issue_at: '',
                        issue_date_end_at: '',
                        issue_date_start_at: '',
                        number: ''
                    },
                    passport: {
                        img_back: '',
                        img_front: '',
                        issue_at: '',
                        issue_date_end_at: '',
                        issue_date_start_at: '',
                        number: ''
                    }
                },
                nows: '',
                is_handled: '',
                address: {
                    area: [],
                    country: '',
                    details: '',
                    foreign: ''
                },
                child_in_hk: {
                    has: '',
                    info: ''
                },
                electronic_visa_date: '',
                hk_identity: '',
                // 
                permanent_residence: 0,
                permanent_reside_year: '',
                permanent_reside_month: '',
            }
        },
        // 是否随行
        followChange(v) {
            this.saveInfo(v)
        },
        // 保存
        async saveInfo(v) {
            if (!v) {
                this.data.spouse = this.$refs.editSopuse.ruleForm
            }
            try {
                this.isLoading = true
                const { code, data } = await postClientInfo(this.data)
                if (code === 200) {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    })
                    this.data.spouse.isEdit = false
                    this.data.spouse = data.spouse
                    this.data.component_score = data.component_score
                    this.$set(this.data.spouse, 'isEdit', false)
                }
            } finally {
                this.isLoading = false
            }
        },
        // 编辑
        editInfo(item) {
            item.isEdit = true
        },
        // 保存
        checkInfo() {
            Promise.all([this.$refs.editSopuse.checkParm()])
            .then(async () => {
                this.saveInfo()
            })
            .catch((err) => {
                console.log(err)
                setTimeout(() => {
                    this.$utils.scrollIntoError(this)
                }, 100)
            })
        },
        // 取消
        cancel(item) {
            if (item.id) {
                item.isEdit = false
            } else {
                this.data.spouse = null
            }
        }
    }
}
</script>

<style lang="less" scoped>
.tooltip{
    display: inline-block;
    border-radius: 100%;
    background: #1296db;
    color: #fff;
    width: 20px;
    height: 20px;
    line-height: 20px;
    text-align: center;
    border: 1px #377395 solid;
    // height: 100%;
}
.content{
    padding: 3px;
    div{
        margin: 10px 0;
    }
}
</style>
