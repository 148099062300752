<!--
 * @Author: Jacob.Zhang
 * @Description: 个人信息表 - 主申请人信息
-->
<template>
    <div v-loading="isLoading">
        <Container title="主申请人" padding="0">
            <div class="title-box">
                <span>主申信息</span>
            </div>
            <!-- 回显信息 -->
            <UserDetail :info="currentItem" />
        </Container>
    </div>
</template>

<script>
import UserDetail from './C/UserDetail.vue'
import { mapState } from 'vuex'
export default {
    props: {
        info: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    components: {
        UserDetail,
    },
    created() {
        this.currentItem = JSON.parse(JSON.stringify(this.info))
    },
    computed: {
        ...mapState('gifted', ['dataList'])
    },
    data() {
        return {
            currentItem: {}, // 主申人信息（编辑&回显共用）
            isLoading: false,
        }
    },
    methods: {
        saveInfo() {
            this.$refs[this.info.component].checkInfo()
        },
    }
}
</script>

<style lang="less" scoped>

</style>
